import GithubSlugger from 'github-slugger'
import React from 'react'
import style from './article-summary.module.scss'

const ArticleSummary = ({ headings }) => {
  return (
    <ul className={style.summary}>
      {headings.map((heading) => {
        const slug = GithubSlugger.slug(heading.value)
        return (
          <li
            className={`${style['depth' + heading.depth]} depth-${
              heading.depth
            }`}
            key={slug}
          >
            <a href={`#${slug}`}>{heading.value}</a>
          </li>
        )
      })}
    </ul>
  )
}

export default ArticleSummary
