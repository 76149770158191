import { graphql } from 'gatsby'
import React from 'react'
import Article from 'src/components/article/article'
import Layout from 'src/components/layout/layout'
import SEO from 'src/components/seo'

const PageTemplate = ({ data }) => {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter } = markdownRemark

  return (
    <Layout>
      <SEO title={frontmatter.title} description={frontmatter.description} />
      <Article data={markdownRemark} />
    </Layout>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      timeToRead
      id
      frontmatter {
        path
        title
        categories
        description
        picture
        date
      }
      headings(depth: h6) {
        value
        depth
      }
    }
  }
`
