import React from 'react'
import ArticleEmphasis from 'src/components/article-emphasis/article-emphasis'
import ArticleSummary from '../article-summary/article-summary'
import style from './article.module.scss'

const Article = ({ data }) => {
  return (
    <article
      className={style.article}
      itemScope
      itemType="http://schema.org/Article"
    >
      <header className={style.article__header}>
        <ArticleEmphasis article={data} withMeta={true} />
      </header>

      <div
        className={`${style.article__content} content`}
        dangerouslySetInnerHTML={{ __html: data.html }}
      />

      <div className={style.article__summary}>
        <div className={`${style.article__summaryinner} content`}>
          <p className={style.article__summarytitle}>Sommaire</p>
          <ArticleSummary headings={data.headings} />
        </div>
      </div>
    </article>
  )
}

export default Article
